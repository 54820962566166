<template>
  <div class="page">
    <List el="xxsm" class="group-list">
      <List el="xsm" class="group" @click="toggleAutoAdvance">
        <List el="column-between xsm">
          <Text el="bold">Auto Advance</Text>
          <div el="toggle" :class="{ active: activeGroup.autoAdvance }"></div>
        </List>
        <Text>Auto advance to next section & distribute remaining time</Text>
      </List>
      <div class="google-btn">Sign In</div>
      <div>
        <div el="status-info" v-if="screenLock">Screen Lock Active</div>
        <div el="status-warning" v-else>Screen Lock Disabled</div>
      </div>
    </List>
  </div>
</template>

<script>
// import ViewGroup from "@/components/ViewGroup.vue";
// import Icon from "@/components/btn/Icon.vue";
import {
  // Layout,
  // Header,
  // Btn,
  List,
  // Accordion,
  // Input,
  Text,
  // Link,
  // Modal,
  // Alert,
} from "@/design-system";

export default {
  name: "Home",
  components: {
    // ViewGroup,
    // Icon,
    // Layout,
    // Header,
    // Btn,
    List,
    // Accordion,
    // Input,
    Text,
    // Link,
    // Modal,
    // Alert,
  },
  data() {
    return {
      groups: this.$store.state.groups,
      screenLock: this.$store.state.screenLock,
    };
  },
  methods: {
    toggleAutoAdvance() {
      this.activeGroup.autoAdvance = !this.activeGroup.autoAdvance;
    },
  },
  computed: {
    activeGroup() {
      return this.groups.list.find((item) => item.id === this.groups.active);
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.group-list {
  padding: 0 12px;
  max-width: 400px;
  margin: auto;
}
.group-summary {
  user-select: none;
}
.group {
  user-select: none;
  --bg-color: hsla(0, 0%, 100%, 0.05);
  border-radius: 10px;
  padding: 15px;
  transition: 0.2s;
}
.group:hover
// .group.active 
 {
  background-color: hsla(0, 0%, 100%, 0.2);
  color: white;
  cursor: pointer;
}
.google-btn {
  height: 50px;
  background-color: hsla(0, 0%, 100%, 0.05);
  border-radius: 10px;
  padding: 0 20px;
  display: grid;
  grid-gap: 8px;
  align-items: center;
  color: hsla(0, 0%, 100%, 0.87);
  font-weight: 500;
  text-align: center;
  background-image: url(https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg);
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: 16px center;
  cursor: pointer;
  user-select: none;
  transition: 0.3s;
}
.google-btn:hover {
  background-color: hsla(0, 0%, 100%, 0.2);
  color: white;
  cursor: pointer;
}
</style>
